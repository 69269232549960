/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

ion-content, ion-content.ion-padding {
  --background: var(--ion-content-background-color);
  --padding-bottom: calc(max(env(safe-area-inset-bottom), var(--ion-padding, 16px)));
}

ion-footer {
  background-color: var(--ion-content-background-color);
  padding: var(--ion-padding, 16px);
  padding-bottom: calc(max(env(safe-area-inset-bottom), var(--ion-padding, 16px)));

  &.vertical-buttons {
    ion-button + ion-button {
      margin-top:  calc(var(--ion-padding, 16px) / 2);
    }
  }
}


@media only screen and (min-width: 290px) and (max-width: 768px) {
  ion-modal:not(.full-screen, [trigger]) {
    --max-height: 80vh !important;
    --max-width: 290px !important;
    padding-top: env(safe-area-inset-top, 20px);
  }
}

ion-modal.full-screen {
  top: env(safe-area-inset-top, 0);
}

/**
 * This will adapt the modal height to its content size
 * also you should replace <ion-content> with <div class="inner-content">
 * @see https://n6k.atlassian.net/browse/OPTR-19365
 */
ion-modal.auto-height {
  &.bottom {
      align-items: flex-end;
  }

  --height: auto;
  --max-height: 90vh !important;

  .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
          max-height: 80vh;
          overflow: auto;
          padding: 10px;
      }
  }
}


ion-modal.date-picker-modal {
  --ion-background-color: transparent;
  padding: var(--ion-padding, 16px);

  // Ideally we use ::part(scroll) with display:flex, align-items:center & justify-content:center
  // but this is not supported on iOS safari 12.x . Therefore we have to use position absolute
  //
  ion-datetime {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  ion-content {
    --background: transparent;
  }
}

*[secondary-text] {
  color: rgba(var(--ion-text-color-rgb, 0,0,0), 0.54);
}

.custom-id {
  font-size: 13px;
  color: rgba(var(--ion-text-color-rgb, 0,0,0), 0.54);
}

ion-icon[src] {
  width: 22px;
  height: 22px;
  color: var(--icon-color);
}

*[hidden] {
  display: none !important;
}

// Workaround for a iOS specific bug that causes the input characters (except
// the first) to not be visible. This workaround causes the input and textareas
// to be rendered in a separate rendering context working around the bug.
//
// https://aerotwist.com/blog/on-translate3d-and-layer-creation-hacks/
//
input,
textarea {
  -webkit-transform: translateZ(0px);
}

*[ellipsis] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

h6 {
  line-height: 24px;
  margin-bottom: 13px;
  margin-top: 17px;
  color: rgba(var(--ion-text-color-rgb, 0,0,0), 0.88);
  font-size: 0.93rem;
}

.color-warning {
  color: var(--ion-color-warning, orange);
}

.fw-500 {
  font-weight: 500;
}

.fz-12 {
  font-size: 12px;
}

hr {
  background-color: rgba(var(--ion-text-color-rgb, 0,0,0), 0.12);
}

[val-message] {
  color: var(--ion-color-danger, red);
}

small {
  font-size: 80%;
}


ion-item-sliding.card {
  box-shadow: var(--card-md-box-shadow);
}

// fix of the following bug https://github.com/ionic-team/ionic-framework/issues/20799
//
html.md ion-modal ion-header ion-toolbar:first-of-type {
  padding-top: 0px;
}

.barcodescanner-active {
  background-color: transparent;

  .ion-page ion-header.scanner-page-header ion-toolbar {
    --background: transparent;
    --color: var(--ion-toolbar-color);
  }
}

.plt-android,
.plt-ios {
  eva-search-input-container form {
    top: env(safe-area-inset-top, 0);
  }
}

.plt-android,
.plt-ios {
  eva-search-results-container {
    --safe-area-inset-top: env(safe-area-inset-top);
    top: calc( var(--toolbar-md-height) + var( --safe-area-inset-top) );
  }
}

.payment-transaction-popover .popover-content {
  width: 200px;
}


ion-card.selectable, ion-button.selectable, ion-item-sliding.selectable, eva-address-book-card.selectable {
  --selectable-outline-size: 2px;

  outline: var(--selectable-outline-size) solid rgba(0, 0, 0, 0);
  transition:
    outline 300ms
  ;

  box-shadow: 0 0 var(--selectable-outline-size) 0 rgba(0,0,0,0.1), 0 var(--selectable-outline-size) var(--selectable-outline-size) 0 rgba(0,0,0,0.2);


  &.warn {
    outline: calc(var(--selectable-outline-size) / 2) solid var(--ion-color-warning, orange);
  }

  &.valid {
    outline: calc(var(--selectable-outline-size) / 2) solid var(--ion-color-primary, blue);
  }

  &.disabled {
    outline: calc(var(--selectable-outline-size) / 2) solid var(--grey-light, grey);
  }

  &.selected {
    outline: var(--selectable-outline-size) solid var(--ion-color-primary, blue);
    box-shadow: none;
  }

  &.invalid {
    outline: calc(var(--selectable-outline-size) / 2) solid var(--ion-color-danger, red);
  }

}

ion-alert button.alert-danger {
  color: var(--ion-color-danger, red) !important;
}

page-product-detail, page-product-bundle-details {
  .mat-tab-label {
    width: 100%;
  }
}

// Autcomplete styles
// @see https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--ion-color-primary, white);
  box-shadow: 0 0 0px 100px var(--ion-item-background, var(--ion-background-color, #fff)) inset;
}

h6 {
  margin-top: var(--ion-padding);
}

.task-action-sheet {
  .action-sheet-group.sc-ion-action-sheet-md:last-child {
    margin-top: 2px;
  }
}

/*
 *
 * CSS Util clases
 *
*/

.w-100 {
  width: 100% !important;
}

.h-100{
  height: 100% !important;
}
